import dayjs from 'dayjs';

import { ALL_COUNTRIES, DEFAULT_COUNTRY } from './constants/countries';
import {
  DEFAULT,
  PACK_PRICE_AVAILABILITY,
  PHONE_NUMBER_REGEX,
  WITHOUT_COUNTRY_CODE
} from './constants/common';
import { CURRENCY } from './constants/finance';
import {
  SHOW_INSTANT_CASHOUT_PLAN,
  SHOW_PRICING_SHECDULE_PLAN
} from './constants/pricing';
import { visitorCountryCode } from 'utils/helpers';
import {
  SHOW_BOSTA_INSIGHTS,
  VALU_COUNTRIES,
  BOSTA_CAPITAL_AVAILABLE
} from './constants/resources';
import {
  INTERNATIONAL_LANDING_IMAGE,
  PHONE_VALIDATTION_COUNTRY
} from './constants/international-shipping';

const userCountryCode = () =>
  localStorage.getItem('countryCodeName') ||
  JSON.parse(localStorage.getItem('userInfo'))?.user?.country?.code;

const currentUserCountry = () =>
  ALL_COUNTRIES.find(({ codeName }) => codeName === userCountryCode()) ||
  DEFAULT_COUNTRY;

const currentUserCountryCode = currentUserCountry().codeName;

export const getCurrentUserCountryData = () => {
  return currentUserCountry();
};

export const setCurrentUserCountryData = ({ codeName }) => {
  localStorage.setItem('countryCodeName', codeName);
};

/* common */
export const getPhoneNumberRegex = ({
  countryCode = currentUserCountryCode,
  withoutCountryCode
}) => {
  return PHONE_NUMBER_REGEX[countryCode][
    withoutCountryCode ? WITHOUT_COUNTRY_CODE : DEFAULT
  ];
};

export const getUserCurrency = (cod) => {
  if (cod !== undefined) {
    return CURRENCY(cod)[visitorCountryCode];
  }

  return CURRENCY()[visitorCountryCode].type;
};

export const isInstantCashoutPlanAvailable = () => {
  return SHOW_INSTANT_CASHOUT_PLAN[visitorCountryCode];
};

export const isPricingPlansTableAvailable = () => {
  return SHOW_PRICING_SHECDULE_PLAN[visitorCountryCode];
};

export const isBostaInsightsAvailable = () => {
  return SHOW_BOSTA_INSIGHTS[visitorCountryCode];
};

export const isValUAvailable = () => {
  return VALU_COUNTRIES[visitorCountryCode];
};

export const isBostaCapitalAvailable = () => {
  return BOSTA_CAPITAL_AVAILABLE[visitorCountryCode];
};

export const internationalShippingFormPhoneValidationCountry = () => {
  return PHONE_VALIDATTION_COUNTRY[visitorCountryCode];
};

export const isPackPriceAvailable = () => {
  return (
    PACK_PRICE_AVAILABILITY[visitorCountryCode] && dayjs() < dayjs('2024-05-15')
  );
};

export const getInternationalShippingLandingImage = () => {
  return INTERNATIONAL_LANDING_IMAGE[visitorCountryCode];
};
